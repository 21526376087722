<template>
  <div style="margin-top: 200px">
    <h1 class="white--text text-center">404 Not found</h1>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>